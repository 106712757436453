// Polyfills
import 'promise-polyfill/src/polyfill';

// NPM packages
import lozad                                                from 'lozad';
import Swiper, { Navigation, Pagination, Autoplay, Lazy }   from 'swiper';
import { disableBodyScroll, enableBodyScroll }              from 'body-scroll-lock';
import SlideUpDown                                          from 'vue-slide-up-down';

Swiper.use([Navigation, Pagination, Autoplay, Lazy]);

// Lozad
window.lozadObserver = lozad('.lozad', {
    loaded: el => {
        const cropPos = el.getAttribute('data-crop-pos');
        if (cropPos) {
            const img = el.getElementsByTagName('img');
            if (img) img[0].style.objectPosition = cropPos;
        }
        el.classList.add('loaded');
    }
});

// Import our CSS
import '../css/app-base.pcss';
import '../css/app-components.pcss';
import '../css/app-utilities.pcss';

import 'swiper/swiper-bundle.css';

// App main
const main = async () => {
    // Async load the vue module
    const { default: Vue } = await import(/* webpackChunkName: "vue" */ 'vue');
    const { default: axios } = await import( /* webpackChunkName: "axios" */ 'axios');
    const VueCookies = await import( /* webpackChunkName: "vue-cookies" */ 'vue-cookies');

    Vue.component('slide-up-down', SlideUpDown);
    
    Vue.use(VueCookies);
    
    // Vue.component('slide-up-down', SlideUpDown);
    
    window.axios = axios;
    
    // Create our vue instance
    const vm = new Vue({
        el: "#app",
        delimiters: ['${', '}'],
        
        components: {
            'course-filter': () => import(/* webpackChunkName: "confetti" */ '../vue/CourseFilter.vue'),
            'blog-filter': () => import(/* webpackChunkName: "confetti" */ '../vue/BlogFilter.vue'),
        },
        
        data: {
            /*Global*/
            lastScrollPos: 0,

            // Cookie consent
            showStatusBar: true,

            /*nav*/
            hideNav: false,
            showOfferFlap: false,
            mobileNavIsOpen: false,
            mobileNavActiveItemId: '',

            /*search*/
            searchResultsActiveTab: 'pages',
        },
    
        created() {
            window.addEventListener('scroll', this.onWindowScroll);
        },
    
        mounted() {
            window.lozadObserver.observe();

            this.initBlockReferencesCompact();
            this.initBlockReferencesTiles();

            this.$nextTick(function() {
                window.addEventListener('resize', this.getWindowWidth);
                this.getWindowWidth()
            })
    
            // Init formie
            if (window.Formie) {
                window.Formie.initForms();
            }
        },
    
        beforeDestroy() {
            window.removeEventListener('scroll', this.onWindowScroll);
            window.removeEventListener('resize', this.getWindowWidth);
        },
        
        methods: {
            onWindowScroll() {
                const isScrollingDown = window.pageYOffset > this.lastScrollPos;
                if (!this.mobileNavIsOpen) this.hideNav = isScrollingDown && (window.pageYOffset > 234);
                this.lastScrollPos = window.pageYOffset;
            },

            getWindowWidth(event) {
                let isMakingWider = document.documentElement.clientWidth > 1440;
                if (this.mobileNavIsOpen && isMakingWider) this.toggleMobileNav();
            },

            toggleMobileNav() {
                this.mobileNavIsOpen = !this.mobileNavIsOpen;
                this.mobileNavIsOpen ? disableBodyScroll(this.$refs.navMobile) : enableBodyScroll(this.$refs.navMobile);
            },
    
            closeStatusBar(siteGroup,slug) {
                VueCookies.set(`${siteGroup}-status-${ slug }`, 'hidden', '7d');
                this.showStatusBar = false;
            },

            toggleMobileNavAccordion(id) {
                this.mobileNavActiveItemId = this.mobileNavActiveItemId === id ? '' : id;
            },

            initBlockReferencesCompact() {
                const blockEl = document.querySelectorAll('.block-references-compact');
                for (let block of blockEl) {
                    let swipeEl = (block.getElementsByClassName('swiper-container').length) ? block.getElementsByClassName('swiper-container')[0] : false;
                    if (swipeEl) {
                        new Swiper(swipeEl, {
                            // Disable preloading of all images
                            preloadImages: false,
                            // Enable lazy loading
                            lazy: true,
                            loop: true,
                            slidesPerView: 1,
                            centeredSlides: true,
                            speed: 1000,
                            autoplay: {
                                delay: 2000,
                                disableOnInteraction: false
                            },
                            breakpoints: {
                                // when window width is >= 768px
                                768: {
                                    slidesPerView: 3
                                },
                                1024: {
                                    slidesPerView: 4,
                                    centeredSlides: false
                                }
                            }
                        });
                    }
                }
            },

            initBlockReferencesTiles() {
                const blockEl = document.querySelectorAll('.block-references-tiles');
                for (let block of blockEl) {
                    let swipeEl = (block.getElementsByClassName('swiper-container').length) ? block.getElementsByClassName('swiper-container')[0] : false;
                    if (swipeEl) {
                        const btnNext = (block.getElementsByClassName('swiper-next').length) ? block.getElementsByClassName('swiper-next')[0] : false;
                        const btnPrev = (block.getElementsByClassName('swiper-prev').length) ? block.getElementsByClassName('swiper-prev')[0] : false;

                        new Swiper(swipeEl, {
                            preloadImages: false,
                            lazy: true,
                            slidesPerView: 1.3,
                            slidesOffsetAfter: 0, // 1.5rem
                            speed: 1000,
                            grabCursor: true,
                            watchOverflow: true,
                            navigation: {
                                nextEl: btnNext,
                                prevEl: btnPrev,
                            },
                            pagination: {
                                el: '.swiper-pagination',
                                type: 'fraction',
                                formatFractionCurrent: number => number < 10 ? '0' + number : number,
                                formatFractionTotal: number => number < 10 ? '0' + number : number,
                                renderFraction: (currentClass, totalClass) => `<span class="${ currentClass } numbers"></span><span class="mx-1">/</span><span class="${ totalClass } numbers"></span>`
                            },
                            breakpoints: {
                                // when window width is >= 768px
                                768: {
                                    slidesPerView: 2.8
                                }
                            }
                        });
                    }
                }
            }
        },
    
        computed: {}
    });
    
    return vm;
};

// Execute async function
main().then( (vm) => {
});

// Accept HMR as per: https://webpack.js.org/api/hot-module-replacement#accept
if (module.hot) {
    module.hot.accept();
}
